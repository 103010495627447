import styled from 'styled-components';

interface ISvgComponent {
  className?: string
  fill: string
  opacity: string
};

interface ISvgComponentContainer  {
  pointer?: boolean
};

export const Svg: React.FC<ISvgComponent> = ({fill, opacity, className}) => {
  return(
      <SvgContainer className={className} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill={fill} fillOpacity={opacity} d="M0,0L0,256L45,256L45,192L90,192L90,96L135,96L135,128L180,128L180,256L225,256L225,64L270,64L270,192L315,192L315,288L360,288L360,128L405,128L405,160L450,160L450,288L495,288L495,0L540,0L540,0L585,0L585,288L630,288L630,256L675,256L675,160L720,160L720,64L765,64L765,96L810,96L810,128L855,128L855,224L900,224L900,128L945,128L945,128L990,128L990,96L1035,96L1035,96L1080,96L1080,128L1125,128L1125,64L1170,64L1170,224L1215,224L1215,64L1260,64L1260,160L1305,160L1305,32L1350,32L1350,224L1395,224L1395,64L1440,64L1440,320L1395,320L1395,320L1350,320L1350,320L1305,320L1305,320L1260,320L1260,320L1215,320L1215,320L1170,320L1170,320L1125,320L1125,320L1080,320L1080,320L1035,320L1035,320L990,320L990,320L945,320L945,320L900,320L900,320L855,320L855,320L810,320L810,320L765,320L765,320L720,320L720,320L675,320L675,320L630,320L630,320L585,320L585,320L540,320L540,320L495,320L495,320L450,320L450,320L405,320L405,320L360,320L360,320L315,320L315,320L270,320L270,320L225,320L225,320L180,320L180,320L135,320L135,320L90,320L90,320L45,320L45,320L0,320L0,320Z"></path>
        </svg>
      </SvgContainer>
  );
};

const SvgContainer = styled.div<ISvgComponentContainer>`
  cursor: ${props => props.pointer ? 'pointer' : 'none'};
  pointer-events: ${props => props.pointer ? 'all' : 'none'};;
  position: absolute;
  width: 100%;
  bottom: -4px;
`